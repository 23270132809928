<button (click)="OnLogout()"  >Logout</button>
<mat-card>
    <mat-card-content>
        <div class="header">
            <P>Book travel with Octank Travel Portal</P>
        </div>
        <form (ngSubmit)="onBook()" name="BookingForm" [formGroup]="bookingForm">
            <div class="nameInput">
                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Name</mat-label>
                    <input formControlName="name" matInput placeholder="Enter name" required />
                    <mat-error *ngIf="!bookingForm.controls['name'].valid">
                        Name is required
                    </mat-error>
                </mat-form-field>
            </div>

            <div>
                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Cost</mat-label>
                    <input formControlName="cost" matInput required />
                    <mat-error *ngIf="!bookingForm.controls['cost'].valid">
                        Cost is required
                    </mat-error>
                </mat-form-field>
            </div>
            <div>

                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Payment Type</mat-label>
                    <input formControlName="paymenttype" matInput readonly/>
                    <mat-error *ngIf="!bookingForm.controls['paymenttype'].valid">
                        Payment Type is required
                    </mat-error>
                </mat-form-field>
 
                    <!-- <mat-form-field appearance="fill">
                        <mat-label>Payment Type</mat-label>
                        <mat-select FormControlName="paymenttype">
                          <mat-option [value]="'CC'">
                            Credit Card
                          </mat-option>
                          <mat-option [value]="'PP'">
                            Paypal
                          </mat-option>
                        </mat-select>
                        <mat-error *ngIf="!bookingForm.controls['paymenttype'].valid">
                            Payment Type is required
                        </mat-error>
                      </mat-form-field>  -->
                      
            </div>
            <button mat-flat-button color="primary">Book</button>
        </form>
    </mat-card-content>
</mat-card>

