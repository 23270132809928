import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-book',
  templateUrl: './book.component.html',
  styleUrls: ['./book.component.scss']
})
export class BookComponent implements OnInit {
  hide: boolean = true;
  selected = "CC";
  private Url = 'https://576oe2wtml.execute-api.us-east-2.amazonaws.com/prod/book/?';
  
  constructor(private fb: FormBuilder,
    private http: HttpClient,
    private router: Router,
    private sb: MatSnackBar) { }

  ngOnInit(): void {
  }

  OnLogout(){
    this.router.navigate(['']);
  }

  onBook() {    
    if (!this.bookingForm.valid) {
      return;
    }
    // name=Parth&cost=1005&paymenttype=CC&date=
    const url = 
    `${this.Url}name=` 
    + this.bookingForm.value.name + '&cost=' 
    + this.bookingForm.value.cost+'&paymenttype='
    + this.bookingForm.value.paymenttype+'&date=%272021-05-29%27';

    console.log(this.bookingForm.value);
    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };
    this.http.get<any>(url,requestOptions ).subscribe(
      (response) => {                           //next() callback
        if(response.length > 0 ){          
          this.sb.open("Booking created Successfully", "close");
        }
      },
      (error) => {                              //error() callback
        console.error('Request failed with error');
        // this.errorMessage = error;
        // this.loading = false;
      });


  }

  bookingForm: FormGroup = this.fb.group({
    name: ['', [Validators.required, Validators.minLength(2)]],
    cost: ['', [Validators.required, Validators.minLength(2)]],
    paymenttype: ['CC', [Validators.required, Validators.minLength(2)]],
  })

  

}
