import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { login } from '../result';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  hide: boolean = true;
  private Url = 'https://576oe2wtml.execute-api.us-east-2.amazonaws.com/prod/login?'; 
  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private router: Router,
    private sb: MatSnackBar ) {
  }

  ngOnInit() {
  }

  loginForm: FormGroup = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.minLength(6)]]
  })


  onLogin() {
    if (!this.loginForm.valid) {
      return;
    }
    // const url = `${this.Url}/fetch`;
    const url = `${this.Url}username=` + this.loginForm.value.email + '&password=' + this.loginForm.value.password;
    
    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };
    
    this.http.get<login[]>(url,requestOptions ).subscribe(
      (response) => {                           //next() callback
        if(response.length > 0 ){          
          if(response[0].password == this.loginForm.value.password){
            this.router.navigate(['book'])
          }
        }else{
          this.sb.open("Login Failed", "Close");
        }
      },
      (error) => {                              //error() callback
        console.error('Request failed with error')
        // this.errorMessage = error;
        // this.loading = false;
      });

    console.log(this.loginForm.value);
  }

}
