<mat-card>
    <mat-card-content>
      <div class="header">
        <P>Sign Into Your Oktank Travel App</P>
      </div>
      <form (ngSubmit)="onLogin()" name="loginForm" [formGroup]="loginForm">
        <div class="emailInput">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Email</mat-label>
            <input
              formControlName="email"
              matInput
              placeholder="Enter email address" required
            />
            <mat-error *ngIf="!loginForm.controls['email'].valid">
                Email is required
              </mat-error>
          </mat-form-field>
        </div>

        <div>
          <span>
            <a class="text-link" class="aLink" routerLink="/auth/forgot-password">Forgot Password?</a>
          </span>
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Password</mat-label>
            <input formControlName="password" matInput [type]=" hide ? 'password' : 'text'" required />
            <button  mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide Password'"
            [attr.aria-pressed]="hide">
            <mat-icon>
                {{hide ? 'visibility_off' : 'visibility'}}
            </mat-icon>
        </button>
        <mat-error *ngIf="!loginForm.controls['password'].valid">
            Password is required
          </mat-error>
        </mat-form-field>
        </div>
        <button mat-flat-button color="primary">Login</button>
      </form>

      <div class="button-row">
        <p>Create New Account</p>
      </div>
    </mat-card-content>
  </mat-card>